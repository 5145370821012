<template>
    <span>{{shippingType}}</span>
</template>

<script>
    import {shippingTypes} from '../../constants/cake-constants'

    export default {
        name: "ShippingType",
        props: {
            value: {
                type: String,
                required: true
            }
        },
        computed: {
            shippingType() {
                return shippingTypes[this.value] ? shippingTypes[this.value] : "Desconocido"
            }
        }
    }
</script>

<style scoped>

</style>