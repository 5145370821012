<template>
    <el-input
        v-model="currency"
        :placeholder="placeholder"
        :size="size"
        :class="classes"
        @keypress.native="onlyCurrencyKeyCodes"
        @input.native="notifyChange"></el-input>
</template>

<script>
    export default {
        name: "CurrencyInput",
        props: {
            value: {},
            placeholder: {
                type: String
            },
            classes: {
                type: String
            },
            size: {
                type: String
            }
        },
        data() {
            return {
                currency: null
            }
        },
        created() {
            this.currency = this.value;
        },
        methods: {
            onlyCurrencyKeyCodes ($event) {
                let keyCode = ($event.keyCode ? $event.keyCode : $event.which);

                // only allow number and one dot
                if (
                    (keyCode < 48 || keyCode > 57)
                    && (keyCode !== 46 || (!!this.currency && this.currency.toString().indexOf('.') != -1))
                ) { // 46 is dot
                    $event.preventDefault();
                }

                // restrict to 2 decimal places
                if(
                    !!this.currency
                    && this.currency.toString().indexOf('.')>-1
                    && (this.currency.toString().split('.')[1].length > 1)
                ){
                    $event.preventDefault();
                }
            },
            notifyChange() {
                this.$emit('input', this.currency);
            }
        },
        watch: {
            value: function () {
                this.currency = this.value;
            }
        }
    }
</script>

<style scoped>

</style>