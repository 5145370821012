export const messages = { // FIXME check messages
    order: {
        get: {
            error: 'No hemos podido consultar el pedido'
        },
        cancel: {
            success: 'Se ha cancelado el pedido con éxito',
            error: 'No hemos podido cancelar el pedido'
        },
        pricing: {
            error: 'No hemos podido actualizar los precios del pedido'
        },
        toPendingApproval: {
            success: 'Se ha aprobado el pedido con éxito',
            error: 'No hemos podido aprobar el pedido'
        },
        deliver: {
            success: 'Se ha marcado el pedido como entregado con éxito',
            error: 'No hemos podido marcar el pedido como entregado'
        },
        note: {
            success: 'Se ha guardado la nota con éxito',
            error: 'No hemos podido guardar la nota de pedido'
        },
    },
    cakeshop: {
        create: {
            success: 'Se ha creado con éxito una nueva pastelería',
            error: 'Ha ocurrido un error en la creación de la pastelería'
        },
        saveDraft: {
            success: 'Todos los datos se han guardado en modo borrador'
        },
        get: {
            error: 'No hemos podido consultar la pastelería'
        },
        update: {
            success: 'Se han guardado todos los cambios con éxito',
            error: 'Ha ocurrido un error en la actualización'
        },
        status: {
            success: 'Se ha actualizado el status de la pastelería',
            error: 'No se ha podido actualizar el status de la pastelería'
        },
        delete: {
            success: 'Se ha eliminado la pasteleria correctamente',
            false: 'No se podido eliminar la pastelería'
        }
    },
    cakeshopAddress: {
        list: {
            error: 'No se han podido descargar las direcciones'
        },
        update: {
            success: 'Se han guardado todos los cambios con éxito',
            error: 'Ha ocurrido un error en la actualización'
        },
        create: {
            success: 'Se ha creado con éxito una nueva dirección',
            error: 'Ha ocurrido un error al crear la nueva dirección'
        },
        status: {
            success: 'Se ha actualizado la disponibilidad de la dirección',
            error: 'No se ha podido actualizar la disponibilidad de la dirección'
        },
        delete: {
            success: 'Se ha eliminado la dirección',
            false: 'No se podido eliminar la dirección'
        }
    },
    cake: {
        list: {
            error: 'No se han podido descargar las pastelerías'
        },
        update: {
            success: 'Se han guardado todos los cambios con éxito',
            error: 'Ha ocurrido un error en la actualización'
        },
        pricing: {
            success: 'Se han guardado todos los cambios con éxito',
            error: 'Ha ocurrido un error en la actualización'
        },
        create: {
            success: 'Se ha creado con éxito una nueva creación'
        },
        status: {
            success: 'Se ha actualizado el status de la creación',
            error: 'No se ha podido actualizar el status de la creación'
        },
        delete: {
            success: 'Se ha eliminado la creación',
            false: 'No se podido eliminar la creación'
        }
    },
    login: {
        error: 'Usuario o password no válidos'
    },
    image: {
        upload: {
            format: {
                error: 'La imagen debe estar en formato JPG!'
            },
            resolution: {
                error: 'La imagen debe tener una resolución mínima de 1024x768!'
            },
            size: {
                error: 'La imagen excede los 5MB!'
            }
        }
    },
    payment: {
        notifyFailed: {
            success: 'Notificación enviada',
            error: 'Ha ocurrido un problema'
        }
    },
    campaign: {
        create: {
            success: 'Se ha creado con éxito una nueva campaña',
            error: 'Ha ocurrido un error en la creación de la campaña'
        },
        saveDraft: {
            success: 'Todos los datos se han guardado en modo borrador'
        },
        get: {
            error: 'No hemos podido consultar la campaña'
        },
        update: {
            success: 'Se han guardado todos los cambios con éxito',
            error: 'Ha ocurrido un error en la actualización'
        },
        status: {
            success: 'Se ha actualizado el status de la campaña',
            error: 'No se ha podido actualizar el status de la campaña'
        },
        delete: {
            success: 'Se ha eliminado la campaña correctamente',
            error: 'No se podido eliminar la campaña'
        }
    }
};

export const dialogs = {
    order: {

    },
    campaign: {
        cancelWizard: {
            text: '¿Estás seguro de que quieres salir de la creación de una nueva campaña?',
            confirm: 'Si, quiero salir',
            cancel: 'Seguir con nueva campaña'
        },
        enable: {
            text: 'Al habilitar disponibilidad, esta campaña pasaría a status <strong>Publicada</strong> y estaría disponible en Wecake.</br></br>¿Quieres continuar?',
            confirm: 'Si, pasar a publicada',
            cancel: 'No, mantener no publicada'
        },
        disable: {
            text: 'Al deshabilitar disponibilidad, esta campaña pasaría a status <strong>No Publicada</strong> y no estaría disponible en Wecake.</br></br>¿Quieres continuar?',
            confirm: 'Si, pasar a no publicada',
            cancel: 'No, mantener publicada'
        },
        delete: {
            text: 'Si elimina esta campaña no podrá volver a gestionar su disponibilidad en Wecake.</br></br>¿Estas seguro que quieres eliminar esta campaña?',
            confirm: 'Eliminar',
            cancel: 'Cancelar'
        },
    },
    cakeshop: {
        cancelWizard: {
            text: '¿Estás seguro que quieres salir? Se guardará en borrador esta nueva pastelería con los pasos que has completado.',
            confirm: 'Salir',
            cancel: 'Seguir con nueva pastelería'
        },
        enable: {
            text: 'Al habilitar la disponibilidad de esta pastelería pasaría a status <strong>Publicada</strong> y estaría disponible en Wecake.</br></br>¿Quieres continuar?',
            confirm: 'Si, pasar a publicada',
            cancel: 'No, mantener no publicada'
        },
        disable: {
            text: 'Al deshabilitar la disponibilidad de esta pastelería pasaría a status <strong>No Publicada</strong> y no estaría disponible en Wecake.</br></br>¿Quieres continuar?',
            confirm: 'Si, pasar a no publicada',
            cancel: 'No, mantener publicada'
        },
        cancel: {
            text: 'Si elimina esta pastelería no podrá volver a gestionar su disponibilidad en Wecake.</br></br>¿Estas seguro que quieres eliminar esta Pastelería?',
            confirm: 'Si, quiero eliminar',
            cancel: 'Cancelar eliminación'
        },
        newBaker: {
            text: 'Bienvenido a tu pastelería en Wecake.</br></br>Necesitas completar la descripción general de tu pastelería y al menos una de tus creaciones para poder recibir pedidos a través de nuestra app.</br></br>¿Tienes toda la información y fotografías?',
            confirm: 'Comenzamos',
        },
    },
    cake: {
        cancelWizard: {
            text: '¿Estás seguro de que quieres salir del registro de una nueva creación?',
            confirm: 'Si, quiero salir',
            cancel: 'Seguir con nueva creación'
        },
        enable: {
            text: 'Al habilitar disponibilidad, esta creación pasaría a status <strong>Publicada</strong> y estaría disponible en Wecake.</br></br>¿Quieres continuar?',
            confirm: 'Si, pasar a publicada',
            cancel: 'No, mantener no publicada'
        },
        disable: {
            text: 'Al deshabilitar disponibilidad, esta creación pasaría a status <strong>No Publicada</strong> y no estaría disponible en Wecake.</br></br>¿Quieres continuar?',
            confirm: 'Si, pasar a no publicada',
            cancel: 'No, mantener publicada'
        },
        cancel: {
            text: 'Si elimina esta creación no podrá volver a gestionar su disponibilidad en Wecake.</br></br>¿Estas seguro que quieres eliminar esta creación?',
            confirm: 'Eliminar',
            cancel: 'Cancelar'
        },
        disableCustomization: {
            text: 'Esta personalización tiene opciones añadidas, si la desactivas se perderán.</br></br>¿Estas seguro que quieres desactivarla?',
            confirm: 'Si, desactivar',
            cancel: 'Cancelar'
        },
    },
    cakeshopAddress: {
        cancelNewAddress: {
            text: '¿Estás seguro de que quieres salir del registro de una nueva dirección?',
            confirm: 'Si, quiero salir',
            cancel: 'Seguir con nueva dirección'
        },
        changeAvailability: {
            text: '¿Está seguro de que quiere modificar la diponibilidad de esta dirección?',
            textOneLeft: 'Es la única dirección disponible, si la desactivas, la pastelería <b>no se mostrará públicamente en la app</b> ¿Quieres continuar?',
            confirm: 'Si, modificar',
            cancel: 'No, mantener disponibilidad'
        },
        cancel: {
            text: '¿Estas seguro que quieres eliminar esta dirección?',
            confirm: 'Eliminar',
            cancel: 'Cancelar'
        },
    },
    image: {
        upload: {
            replace: {
                text: 'Si sube una nueva imagen eliminará la actual.</br></br>¿Quieres continuar?',
                confirm: 'Si, subir nueva imagen',
                cancel: 'No, mantener la actual'
            },
            maximum: {
                text: 'Como máximo puedes subir 3 imágenes. Si quieres subir una nueva, debes eliminar alguna de las seleccionadas.',
                confirm: 'De acuerdo'
            }
        }
    }
}
