export const shippingTypes = {
    RUNNER: "Mensajero",
    SPECIAL: "Envío Especial",
    IN_SHOP: "Recogida en Pastelería"
};

export const tags = [
    "Sin Gluten",
    "Sin Huevo",
    "Sin Lácteos",
    "Sin Azúcar",
    "Sin frutos secos",
    "Vegana",
    "Dieta",
    "Fitness",
    "Tradicional",
    "Europea",
    "Americana",
    "Cumpleaños",
    "San Valentín",
    "Navidad",
    "Kosher",
    "Halal",
    "Personalizable",
    "Diseño",
    "Original"
];
