<template>
    <div>
        <el-row>
            <el-col>
                <div
                    v-if="mainImageUrl"
                    class="preview img-background"
                    v-bind:style="{ backgroundImage: 'url(' + mainImageUrl + ')' }">
                    <i v-if="editor" class="el-icon-delete icon-gray icon-cornered m-2 p-2" @click="deleteMainImage"></i>
                </div>

                <div v-else :class="[error ? 'red-border' : 'gray-border', 'preview']"><i class="el-icon-picture-outline icon-gray icon-centered"></i></div>
            </el-col>
        </el-row>

        <el-row v-if="imageUrls.length > 1" class="mt-2">
            <el-col class="d-flex flex-row">
                <div
                    v-for="(imageUrl, index) in imageUrls"
                    :key="index"
                    class="miniature img-background mr-2"
                    v-bind:style="{ backgroundImage: 'url(' + imageUrl + ')'}"
                    @click="mainImageUrl = imageUrl"></div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: "ImageSlider",
        props: {
            imageUrls: {
                type: Array,
                default: function () {
                    return [];
                }
            },
            editor: {
                type: Boolean
            },
            error: {
                type: Boolean
            }
        },
        data() {
            return {
                mainImageUrl: null
            }
        },
        created() {
            this.initialize();
        },
        methods: {
            initialize() {
                if (this.imageUrls.length > 0) {
                    this.mainImageUrl = this.imageUrls[0];
                } else {
                    this.mainImageUrl = null;
                }
            },
            deleteMainImage() {
                this.$emit('delete', this.mainImageUrl);
            }
        },
        watch: {
            imageUrls: function () {
                this.initialize();
            }
        }
    }
</script>

<style scoped>
    .preview {
        width: 100%;
        padding-top: 75%; /* 4:3 Aspect Ratio */
        background: rgba(229, 229, 229, 0.5);
        border-radius: 4px;
    }

    .gray-border {
        border: 1px dashed #C4C4C4;
    }

    .red-border {
        border: 1px solid #D89172;
    }

    .miniature {
        width: 20%;
        padding-top: 15%; /* 4:3 Aspect Ratio */
        border-radius: 4px;
        cursor: pointer;
    }

    .img-background {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border: 0 !important;
    }

    .icon-cornered {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 20px;
        background: rgba(255, 255, 255, 0.8);
        border-radius: 50%;
        cursor: pointer;
    }

    .icon-centered {
        position: absolute;
        top: 50%;
        left: 50%;
        -moz-transform: translateX(-50%) translateY(-50%);
        -webkit-transform: translateX(-50%) translateY(-50%);
        transform: translateX(-50%) translateY(-50%);
        font-size: 40px;
    }
</style>